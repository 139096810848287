<template>
  <base-section id="about-our-product">
    <v-container>
      <v-row>
        <v-col
          cols="12"
        >
          <base-subheading
            size="display-1"
            title="SYNTHETIC GROWTH FACTORS"
            space="0"
          />

          <base-title
            class="primary--text"
            title="FUNCTIONAL NUCLEIC ACIDS AS GROWTH FACTOR REPLACEMENTS"
            tag="div"
          />

          <base-body class="text-justify">
            Short functional Nucleic Acids, so called Aptamers, are able to recognize and bind to target structures in a highly specific manner. The nucleic acid sequence of the aptamers leads to a specific structure and thus defines their properties. They are fully synthetic, biologically safe and can be produced in a highly standardized process. They combine a antibody-like binding specificity with the manufacturing and stability advantages of purely synthetic chemical materials.
            In addition, the aptamers we are developing can also induce biological activity at their respective target structure, e.g. a cellular receptor.
            <p class="font-weight-bold">
              Therefore, our aptamers are ideally suited as raw materials for the production of cultivated meat, advanced therapies or even active pharmaceutical ingredients (APIs).
            </p>
          </base-body>
        </v-col>
      </v-row>
    </v-container>

    <base-section-heading
      color="grey lighten-2"
      icon="fas fa-question"
      title="Why Synthtic Growth Factors"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="6"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          title: 'Highly Specific',
          subtitle: 'Precise Use',
          text: 'Synthetic growth factors are comparable to conventional growth factors in terms of binding specificity and biological activity.',
          callout: '01',
        },
        {
          title: 'Stable And Uniform',
          subtitle: 'GMP Compliant',
          text: 'Synthetic growth factors are chemically robust and consistent in each production batch.',
          callout: '02',
        },
        {
          title: 'Versatile',
          subtitle: 'Customizable Product',
          text: 'Synthetic growth factors can be chemically modified in almost any way to suit any cell culture application. For example, the directed coupling onto various matrices can be easily accomplished.',
          callout: '03',
        },
        {
          title: 'Reliable Production',
          subtitle: 'Securely Available',
          text: 'Without the need for a biological manufacturer, the products can be produced efficiently and with almost no quantity restriction.',
          callout: '04',
        },
        {
          title: 'Cost and Resource Efficient',
          subtitle: 'Small Production Footprint',
          text: 'Lean production processes and low material consumption during manufacture make Synthetic growth factors a sustainable and economical class of materials.',
          callout: '05',
        },
        {
          title: 'Biological Safety',
          subtitle: 'Animal Origin-Free',
          text: 'The manufacturing process is completely free of human and animal raw materials, so the products are free of any biological contamination.',
          callout: '06',
        },
      ],
    }),
  }
</script>
